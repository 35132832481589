<template>
  <div class="about">
    <van-nav-bar title="我的" fixed/>
    <br>
    <br>
    <p>
      我是个人中心
    </p>
  </div>
</template>
